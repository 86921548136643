import * as actions from "./actions";

const initialState = {
  user: null,
  isLoggedIn: false,
  board: null,
  stagedTaskList: null
};

export const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_USER:
      return { user: state.user, isLoggedIn: state.isLoggedIn, ...state };

    case actions.LOAD_CARD:
      return Object.assign({}, state, {
        ...state,
        card: action.payload.card,
      });

    case actions.LOAD_TASK:
      state = Object.assign({}, state, {
        ...state,
        task: action.payload.task,
      });
      return state;

    case actions.UNSTAGE_TASKS:
      return Object.assign({}, state, {
        ...state,
        stagedTaskList: [],
      });

    case actions.STAGE_TASK:
      const stagedTasks = state.stagedTaskList;
      let updated = false;
      let newStagedTasks = null;
      if (stagedTasks && stagedTasks[0] !== null && stagedTasks.length > 0) {
        stagedTasks?.forEach(t => {
          if (t.id === action.payload?.id) {
            t.body = action.payload.body;
            updated = true;
          }
        });
        newStagedTasks = [...stagedTasks];
      }
      if (!updated && action.payload !== null) {
        if (stagedTasks && stagedTasks[0] !== null && stagedTasks.length > 0) {
          newStagedTasks.push(action.payload);
        } else {
          newStagedTasks = [action.payload];
        }
      }
      return Object.assign({}, state, {
        ...state,
        stagedTaskList: [...newStagedTasks],
      });

    case actions.UNSTAGE_TASK:
      const stateStagedTasks = state.stagedTaskList;
      const stagedTaskIndex = stateStagedTasks.findIndex(t => t.id === action.payload.taskId);
      stateStagedTasks.splice(stagedTaskIndex, 1);
      return Object.assign({}, state, {
        ...state,
        stagedTaskList: [...stateStagedTasks],
      });

    case actions.LOAD_CARDS:
      const { board, cards, append } = action.payload;
      const loadedCards = append ? state.cards.concat(cards) : cards;

      return Object.assign({}, state, {
        ...state,
        cards: loadedCards,
        lastCardPage: loadedCards.length === (board ? board.totalCards : 0)
      });

    case actions.LOAD_TASK_LIST:
      if (!action.payload?.cardId) {
        console.error(`no card id found to load task list`);
        return state;
      }
      const cardIndex = state.cards?.findIndex(c => c.id === action.payload.cardId);
      if (cardIndex >= 0) {
        state.cards[cardIndex].taskList = action.payload.taskList;
      }
      const newCards = [...state.cards];
      return Object.assign({}, state, {
        ...state,
        cards: newCards,
      });

    case actions.LOAD_BOARD:
      return Object.assign({}, state, {
        ...state,
        board: action.payload.board,
      });

    case actions.LOAD_BOARDS:
      return Object.assign({}, state, {
        ...state,
        boards: action.payload.boards,
      });

    case actions.SET_USER:
      return {
        user: action.payload.user,
        isLoggedIn: action.payload.isLoggedIn,
      };

    case actions.SIGNOUT_USER:
      return { user: null, isLoggedIn: false };

    case actions.SET_BOARD:
      return { board: action.payload.board, ...state };

    case actions.LOAD_SEARCH_RESULT:
      return Object.assign({}, state, {
        ...state,
        searchResult: action.payload.searchResult,
      });

    case actions.RESET_SEARCH_RESULT:
      return Object.assign({}, state, {
        ...state,
        searchResult: null,
        card: null,
        task: null
      });

    // case actions.UPSERT_CARD: {
    //   let currentCards = state.cards ? state.cards.slice() : [];
    //   if (!action.payload.created) {
    //     currentCards = currentCards.map((card) => {
    //       if (card.id === action.payload.card.id) {
    //         card.title = action.payload.card.title;
    //       }
    //     });
    //   }
    //   return Object.assign({}, state, {
    //     ...state,
    //     // card: action.payload.card,
    //     cards: currentCards,
    //   });
    // }

    case actions.SEND_USER_HOME:
      return Object.assign({}, state, {
        ...state,
        sendHome: true,
      });
    default:
      return state;
  }
};
