
import styles from "../styles/Home.module.scss";
import Center from "../components/Layout/Center";
import NavBar from "../components/Navigation/NavBar";

export default function Home() {
  return (
    <Center>
      <NavBar />
      <div className={styles.container}>
        <main className={styles.main}>
          <h1 className={styles.title}>Wut</h1>
        </main>
      </div>
    </Center>
  );
}
