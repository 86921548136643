import React from "react";

import styles from "../../styles/Home.module.scss";
import Center from "../../components/Layout/Center";
import NavBar from "../../components/Navigation/NavBar";

export default function UnderConstructionPage() {
  return (
    <Center>
      <NavBar />
      <div className={styles.container}>
        <main className={styles.main}>
          <h1 style={{color:"white"}}>Sorry, this app is still under construction.</h1>
          <a href="/">Home</a>
        </main>
      </div>
    </Center>
  );
}

