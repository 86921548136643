import {
  Route,
  Routes,
  BrowserRouter
} from "react-router-dom";

import BoardsPage from "./pages/boards";
import BoardPage from "./pages/boards/board";
import CardDetailPage from "./pages/cards/card";
import SorryPage from "./pages/sorry";
import SearchPage from "./pages/search";
import SigninPage from "./pages/auth/signin";
import SignupPage from "./pages/auth/signup";
import TaskDetailPage from "./pages/tasks/task";
import UnderConstructionPage from "./pages/sorry/underConstruction";
import Home from "./pages/index";

function App() {
  return (
    <>
      <BrowserRouter>
        <div className="content">
          <Routes>
            <Route exact path="/sorry" element={<SorryPage />}/>
            <Route exact path="/underconstruction" element={<UnderConstructionPage />}/>
            <Route exact path="/boards/:boardId" element={<BoardPage />} />
            <Route exact path="/boards" element={<BoardsPage />} />
            <Route exact path="/cards/:cardId" element={<CardDetailPage />} />
            <Route exact path="/tasks/:taskId" element={<TaskDetailPage />} />
            <Route exact path="/search" element={<SearchPage />}/>
            <Route exact path="/auth/signin" element={<SigninPage />}/>
            <Route exact path="/auth/signup" element={<SignupPage />}/>
            <Route exact path="/" element={<Home />}/>
          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
