import app from "firebase/app";
import * as actions from "../redux/actions";
import { getBoard, updateBoard } from "./board";
import { batchUpsertMentionsThunk, deleteMentionThunk } from "./mention";
import { batchCreateTasksThunk, deleteTaskThunk, getTaskList } from "./task";

const LIMIT = 5;

export const createCard = async (boardId, card) => {
  const db = app.firestore();
  const cardsRef = db.collection("cards");
  try {
    const cardDocRef = await cardsRef.add({
      boardId,
      title: card.title,
      createdAt: Date.now(),
      active: true,
    });
    card.id = cardDocRef.id;
    return card;
  } catch (err) {
    console.error("Error adding document: ", err);
  }
};

export const getCards = async (boardId, stateCards) => {
  if (boardId === 0 || boardId === undefined) return null;
  const db = app.firestore();
  const lastCard = stateCards[stateCards.length - 1];

  const cards = await db
    .collection("cards")
    .where("boardId", "==", boardId)
    // TODO: we could hide inactives or leave them in list, but this breaks startAfter
    // .orderBy("active", "desc")
    .orderBy("createdAt", "desc")
    .startAfter(lastCard ? lastCard.createdAt : Date.now())
    .limit(LIMIT)
    .get()
    .then((querySnapshot) => {
      const cards = [];
      querySnapshot.forEach(async (card) => {
        const cardData = card.data();
        cards.push({ id: card.id, ...cardData, taskList: [] });
      });
      return cards;
    })
    .catch((error) => {
      console.error("Error getting documents: ", error);
    });
  return cards;
};

export function getCardsThunk(boardId, nextPage = false) {
  return async function fetchCards(dispatch, getState) {
    try {
      const state = getState();
      const cards = await getCards(boardId, nextPage ? state.cards : []);
      const newCards = await Promise.all(cards.map(async card => {
        const taskList = await getTaskList(card.id);
        card.taskList = taskList;
        return card;
      })).then(newCards => {
        return newCards
      });
      const board = await getBoard(boardId);
      dispatch({
        type: actions.LOAD_CARDS,
        payload: {
          cards: newCards,
          append: nextPage,
          board
        }
      });
    } catch (e) {
      console.error(e);
    }
  };
}

export const updateCard = async (updatedCard) => {
  const db = app.firestore();
  delete updatedCard.taskList;
  const card = Object.assign(
    {},
    {
      ...updatedCard,
      modifiedAt: Date.now(),
    }
  );
  await db.collection("cards").doc(card.id).update(card);
  return card;
};

export function updateCardThunk(card) {
  return async function updateCardOuter(dispatch) {
    if (!card) return;
    card = await updateCard(card);
    dispatch(batchUpsertMentionsThunk("cards", [card]));
  };
}

export function upsertCardThunk(boardId, card) {
  return async function upsertCard(dispatch, getState) {
    if (card.title === "") return;
    const create = typeof card.id === "number" || card.id === "" ? true : false;
    if (create) {
      const state = getState();
      card.id = null;
      const tasks = state.stagedTaskList;
      delete card.taskList;
      card = await createCard(boardId, card);
      dispatch(getCardsThunk(boardId, false));
      dispatch(batchCreateTasksThunk(boardId, card.id, tasks));
      await updateBoard({ id: boardId }, 1);
      dispatch({
        type: actions.UPSERT_CARD,
        payload: { card, created: create },
      });
    } else {
      card = await updateCard(card);
      dispatch(getCardsThunk(boardId));
    }
    dispatch(batchUpsertMentionsThunk("cards", [card]));
  };
}

export const deleteCard = async (cardId) => {
  const db = app.firestore();
  try {
    await db.collection("cards").doc(cardId).delete();
    return true;
  } catch (err) {
    console.error(`Error deleting card: ${err}`);
  }
};

export function deleteCardThunk(card) {
  return async function deleteCardOuter(dispatch) {
    if (card.id === "") return;
    const taskList = await getTaskList(card.id);
    taskList.forEach((task) => {
      dispatch(deleteTaskThunk(task.id));
    });
    await deleteCard(card.id);
    await updateBoard({ id: card.boardId }, -1);
    dispatch(deleteMentionThunk(card.id));
  };
}

export const getCard = async (cardId) => {
  if (cardId === 0 || cardId === undefined) return null;
  const db = app.firestore();
  let card = null;
  try {
    const snapshot = await db.collection("cards").doc(cardId).get();
    card = { id: cardId, ...snapshot.data() };
  } catch (err) {
    console.error("Error getting documents: ", err);
  }
  return card;
};

export function getCardDetailThunk(cardId) {
  return async function getCardDetailOuter(dispatch) {
    const card = await getCard(cardId);
    const taskList = await getTaskList(cardId);
    dispatch({
      type: actions.LOAD_CARD,
      payload: { card: { ...card, taskList } },
    });
  };
}


// export const dataRestoreAllCards = async () => {
//   const db = app.firestore();
//   await db.collection("cards")
//     .get()
//     .then((querySnapshot) => {
//       querySnapshot.forEach(async (card) => {
//         const cardData = card.data();
//         await db.collection("cards").doc(card.id).update({
//           ...cardData,
//           active: true,
//         });
//       });
//     })
//     .catch((error) => {
//       console.error("Error getting documents: ", error);
//     });
// };

// export function dataRestoreAllCardsThunk() {
//   return async function dataRestoreCardsOuter() {
//     await dataRestoreAllCards();
//   };
// }

