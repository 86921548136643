import moment from 'moment';

export const timestampToJS = (timestamp) => {
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const date = new Date(timestamp);
  return date.toLocaleDateString(undefined, options);
};

export const daysSinceCreation = (createdAt) => {
  return moment.unix(moment().unix()).diff(moment.unix(createdAt / 1000), 'days');
};