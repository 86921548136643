import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd";

const SecurePage = (props) => {
  const user = useSelector((state) => state.user);
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const sendHome = useSelector((state) => state.sendHome);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn || !user) {
      navigate("/sorry", { replace: false });
    }
  }, [user, isLoggedIn]);

  useEffect(() => {
    if (sendHome) {
      navigate("/underconstruction", { replace: false });
    }
  }, [sendHome]);

  return user && isLoggedIn ? props.children : <Spin />;
};

export default SecurePage;
