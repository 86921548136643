import app from "firebase/app";
import * as actions from "../redux/actions";
import { batchUpsertMentionsThunk, deleteMentionThunk } from "./mention";

export const batchCreateTasks = async (boardId, cardId, tasks) => {
  const db = app.firestore();
  const tasksRef = db.collection("tasks");
  try {
    if (tasks?.length > 0) {
      const batch = db.batch();
      tasks.forEach(async (task) => {
        const newTaskDoc = tasksRef.doc();
        await batch.set(newTaskDoc, {
          boardId,
          cardId,
          createdAt: Date.now(),
          body: task.body,
        });
        task.id = newTaskDoc.id;
      });
      await batch.commit();
    }
  } catch (err) {
    console.error("Error adding document: ", err);
  }
  return tasks;
};

export function batchCreateTasksThunk(boardId, cardId, tasks) {
  return async function batchCreateTasksInner(dispatch) {
    const taskList = await batchCreateTasks(boardId, cardId, tasks);
    dispatch(batchUpsertMentionsThunk("tasks", taskList));
    dispatch({ type: actions.LOAD_TASK_LIST, payload: { cardId, taskList } });
  };
}

export const createTask = async (boardId, cardId, task) => {
  const db = app.firestore();
  const tasksRef = db.collection("tasks").doc();
  try {
    await tasksRef.set({
      boardId,
      cardId,
      body: task.body,
      createdAt: Date.now(),
    });
    task.id = tasksRef.id;
    return task;
  } catch (err) {
    console.error("Error adding document: ", err);
  }
};

export const getTaskList = async (cardId) => {
  if (!cardId || cardId === 0 || cardId === "") return null;
  const db = app.firestore();
  const taskList = await db
    .collection("tasks")
    .orderBy("createdAt", "desc")
    .where("cardId", "==", cardId)
    .get()
    .then((querySnapshot) => {
      const taskList = [];
      querySnapshot.forEach((doc) => {
        taskList.push({ id: doc.id, ...doc.data() });
      });
      return taskList;
    })
    .catch((error) => {
      console.error("Error getting tasks: ", error);
    });
  return taskList;
};

export function getTaskListThunk(cardId) {
  return async function fetchTaskList(dispatch) {
    const taskList = await getTaskList(cardId);
    dispatch({ type: actions.LOAD_TASK_LIST, payload: { cardId, taskList } });
  };
}

export function createTaskThunk(boardId, cardId, task) {
  return async function createTaskOuter(dispatch) {
    if (boardId === "" || cardId === "" || task.body === "") return;
    await createTask(boardId, cardId, task);
    dispatch(batchUpsertMentionsThunk("tasks", [task]));
    const taskList = await getTaskList(cardId);
    dispatch({ type: actions.LOAD_TASK_LIST, payload: { cardId, taskList } });
  };
}

export const updateTask = async (boardId, cardId, updatedTask) => {
  const db = app.firestore();
  const task = Object.assign(
    {},
    {
      boardId,
      cardId,
      body: updatedTask.body,
      modifiedAt: Date.now(),
    }
  );
  try {
    await db.collection("tasks").doc(updatedTask.id).update(task);
    return true;
  } catch (err) {
    console.error(`Error updating task: ${err}`);
  }
};

export function updateTaskThunk(boardId, cardId, task) {
  return async function updateTaskOuter(dispatch) {
    if (boardId === "" || cardId === "" || task.id === "" || task.body === "")
      return;
    await updateTask(boardId, cardId, task);
    dispatch(batchUpsertMentionsThunk("tasks", [task]));
    const taskList = await getTaskList(cardId);
    dispatch({ type: actions.LOAD_TASK_LIST, payload: { cardId, taskList } });
  };
}

export const deleteTask = async (taskId) => {
  const db = app.firestore();
  try {
    await db.collection("tasks").doc(taskId).delete();
    return true;
  } catch (err) {
    console.error(`Error deleting task: ${err}`);
  }
};

export function deleteTaskThunk(taskId) {
  return async function deleteTaskOuter(dispatch) {
    if (taskId === "") return;
    await deleteTask(taskId);
    dispatch(deleteMentionThunk(taskId));
  };
}

export const getTask = async (taskId) => {
  if (taskId === 0 || taskId === undefined) return null;
  const db = app.firestore();
  let task = null;
  try {
    const snapshot = await db.collection("tasks").doc(taskId).get();
    task = { id: taskId, ...snapshot.data() };
  } catch (err) {
    console.error("Error getting documents: ", err);
  }
  return task;
};

export function getTaskThunk(taskId) {
  return async function getTaskOuter(dispatch) {
    const task = await getTask(taskId);
    dispatch({
      type: actions.LOAD_TASK,
      payload: { task },
    });
  };
}
