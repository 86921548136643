import firebase from "firebase/app";
import "firebase/auth";

class Firebase {
  constructor(firestore) {
    this.auth = firebase.auth();
    this.currentUser = null;
    this.firestore = firestore;
  }

  // AUTH API

  doCreateUserWithEmailAndPassword = (email, password) => {
    return this.auth.createUserWithEmailAndPassword(email, password);
  };
  doSignInWithEmailAndPassword = (email, password) => {
    return this.auth.signInWithEmailAndPassword(email, password);
  };
  doSignOut = () => {
    this.currentUser = null;
    this.auth.signOut();
  };
  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);
  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);
  setUser = (authUser) => (this.currentUser = authUser);
  getUser = () => this.currentUser;
  getAuth = () => this.auth;
}

export default Firebase;
