/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Card, Col, List, Row } from "antd";
import { StarFilled, StarOutlined } from "@ant-design/icons";
import styles from "../../styles/Home.module.scss";
import {
  createBoardThunk,
  deleteBoardThunk,
  fetchBoardsThunk,
  updateBoardThunk,
} from "../../model/board";
// import {
//   dataRestoreAllCardsThunk
// } from "../../model/card";
import Center from "../Layout/Center";
import NavBar from "./../Navigation/NavBar";
import TextInput from "../Inputs/TextInput";
import DeleteButton from "../Controls/DeleteButton";
import InfoButton from "../Controls/InfoButton";

const Boards = () => {
  const board = useSelector((state) => state.board);
  const boards = useSelector((state) => state.boards);
  const dispatch = useDispatch();
  const [boardName, setBoardName] = useState("");
  const [dataRestore, setDatarestore] = useState(true);

  const handleNewName = (name) => {
    dispatch(createBoardThunk(name));
    dispatch(fetchBoardsThunk());
    setBoardName("");
  };

  const handleDelete = (boardId) => {
    dispatch(deleteBoardThunk(boardId));
    dispatch(fetchBoardsThunk());
  };

  const toggleFavourite = (board) => {
    dispatch(updateBoardThunk({ id: board.id, favourite: !board.favourite }, true));
  };

  useEffect(() => {
    dispatch(fetchBoardsThunk());
    // move this kind of thing into an admin panel
    // if (dataRestore) {
    //   dispatch(dataRestoreAllCardsThunk());
    //   setDatarestore(false);
    // }
  }, []);

  useEffect(() => {
    dispatch(fetchBoardsThunk());
  }, [board]);

  const formatBoard = (board) => {
    if (!board) return null;
    return (
      <Card
        bodyStyle={{padding: "1rem"}}
        className={[
          styles.board,
          board.favourite ? styles.favouriteBoard : styles.blackBg,
        ].join(" ")}
      >
        <Row>
          <Col span={20}>
            <Link
              key={board.id}
              to={`/boards/${board.id}`}
            >
              <h3 style={{ color: "white" }}>{board.name}</h3>
            </Link>
          </Col>
          <Col span={2}>
            <button
              size={"large"}
              shape={"circle"}
              className={board.favourite ? 
                styles.favouriteButton : styles.favouriteButtonTransparent}
              onClick={() => toggleFavourite(board)}
            >
              {board.favourite ? <StarFilled /> : <StarOutlined />}
            </button>
          </Col>
          <Col span={2}>
            <DeleteButton
              key={`deleteBtn-${board.id}`}
              id={board.id}
              delete={handleDelete}
              style={board.favourite ? 
                styles.favouriteButton : styles.favouriteButtonTransparent}
            />
          </Col>
        </Row>
      </Card>
    );
  };

  return (
    <Center>
      <NavBar />
      <Row>
        <h1 className={styles.inputBig}>Boards</h1>
      </Row>
      <Row className={styles.debugRow}>
        <Col span={24}>
          <TextInput
            txt={boardName}
            onEnter={(txt) => handleNewName(txt)}
            placeholder={"enter a board name and hit enter"}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {boards?.length > 0 ? (
            <List
              header={null}
              footer={null}
              dataSource={boards}
              renderItem={(board) => formatBoard(board)}
            />
          ) : null}
        </Col>
      </Row>
    </Center>
  );
};

export default Boards;
