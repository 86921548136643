import React, { useState, useEffect } from 'react';

import { Button, Card, Col, Form, Input, Row } from "antd";
import { SaveFilled, SaveOutlined } from "@ant-design/icons";
import styles from "../../styles/Home.module.scss";

const TextInput = (props) => {
  const [cssClass, setCssClass] = useState(styles.inputActive);
  const [txtSource, setTxtSource] = useState("");
  const [txt, setTxt] = useState(props.txt);
  const [edited, setEdited] = useState(false);

  useEffect(() => {
    setTxtSource(txt);
  }, []);

  const handleInputChange = (e) => {
    setEdited(e.target.value !== txtSource);
    if (e.key === "Enter") return;
    setCssClass(e.target.value !== txtSource ? styles.inputEdited : styles.inputActive);
    setTxt(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (props.onEnter && e.key === "Enter") {
      setTxtSource(txt);
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (cssClass !== styles.inputSaving) setCssClass(styles.inputSaved);
    props.onEnter(txt);
    setEdited(false);
  }

  return (
    <Row justify="space-around" align="middle">
      <Col span={20}>
        <Input
          id="text"
          placeholder={props.placeholder}
          type="text"
          value={txt}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          className={[
            styles.input,
            cssClass
          ].join(' ')}
          autoComplete="off"
        />
      </Col>
      <Col span={4}>
        <button
          type="primary"
          onClick={() => handleSubmit()}
          disabled={txt === ""}
          className={[
            styles.saveTaskButtonTransparent,
            edited ? styles.saveTaskButtonTransparentActive : styles.saveTaskButtonTransparentInactive
          ].join(' ')}
          size={"large"}
          shape={"circle"}
          style={{ width:"10px" }}
        >
          <SaveFilled />
        </button>
      </Col>
    </Row>
  );
};

export default TextInput;
