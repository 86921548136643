import React from "react";

import Boards from "../components/Boards/Boards";
import SecurePage from "../config/auth/SecurePage";

const BoardsPage = () => {
  return (
    <SecurePage>
      <Boards />
    </SecurePage>
  );
};

export default BoardsPage;
