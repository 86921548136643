/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Input, Form, List, Row, Spin } from "antd";
import styles from "../../styles/Home.module.scss";
import {
  getBoardThunk,
} from "../../model/board";
import {
  getCardsThunk,
  upsertCardThunk,
} from "../../model/card";
import * as actions from "../../redux/actions";

import CardDetail from "../Cards/CardDetail";
import Center from "../Layout/Center";
import NavBar from "./../Navigation/NavBar";
import Task from '../Inputs/Task';

const Board = (props) => {
  const initial_card_state = {
    id: "",
    title: "",
    taskList: [],
    createdAt: null,
  };

  const [currentCard, setCurrentCard] = useState(initial_card_state);
  const lastCardPage = useSelector((state) => state.lastCardPage);
  const [title, setTitle] = useState("");
  const board = useSelector((state) => state.board);
  const cards = useSelector((state) => state.cards);
  const stagedTaskList = useSelector((state) => state.stagedTaskList);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBoardThunk(props.boardId));
    dispatch(getCardsThunk(props.boardId, false));
    dispatch({ type: actions.UNSTAGE_TASKS, payload: {} });
  }, []);

  const onSave = () => {
    dispatch(upsertCardThunk(props.boardId, { ...currentCard, title }));
    setCurrentCard(initial_card_state);
    dispatch({ type: actions.UNSTAGE_TASKS, payload: {} });
    setTitle("");
  };

  const onChangeTitle = (evt) => {
    setTitle(evt.target.value);
  };

  const loadMoreCards = () => {
    dispatch(getCardsThunk(props.boardId, true));
  };

  const formatTask = (task, cardId = null) => {
    return <Task key={task.id} boardId={props.boardId} cardId={cardId} task={task} />
  }

  return (
    <Center>
      <NavBar />
      {board ? <h1 className={styles.boardName}>{board.name}</h1> : <Spin />}
      <div className={styles.addCardForm}>
        <Row>
          <Col span={24}>
            <h3 className={`${styles.inputLabel}`}>Wut?</h3>
            <Form.Item required tooltip="This is a required field">
              <Input
                id="text"
                placeholder={"enter the title of a new card"}
                type="text"
                value={title}
                onChange={onChangeTitle}
                className={[
                  styles.newCardInput,
                  // cssClass
                ].join(' ')}
                autoComplete="off"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <h3 className={`${styles.inputLabel}`}>
              Steps to victory:
            </h3>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Task cardId={null} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {stagedTaskList?.length > 0 ? (
              <List
                split={false}
                footer={null}
                dataSource={stagedTaskList}
                renderItem={(task) => formatTask(task)}
              />
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <hr style={{color:"white"}} />
          </Col>
        </Row>
        <Row>
          <Col span={20} />
          <Col span={4}>
            <Button
              type="primary"
              onClick={onSave}
              disabled={title === ""}
              className={title === "" ? styles.disabledBtn : styles.saveButton}
              size={"large"}
            >
              save
            </Button>
          </Col>
        </Row>
      </div>
      <div>
        <Row>
          <Col span={24}>
            {cards?.length > 0 ? (
              <List
                header={null}
                footer={null}
                dataSource={cards}
                renderItem={(card) => <CardDetail key={card.id} card={card} />}
              />
            ) : null}
            <br />
            <Button
              type="primary"
              onClick={loadMoreCards}
              disabled={lastCardPage}
              className={lastCardPage ? styles.disabledBtn : styles.saveButton}
              size={"large"}
            >
              Load More
            </Button>
          </Col>
        </Row>
      </div>
    </Center>
  );
};

export default Board;

    
  