import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";

import { Col, Input, Row } from "antd";
import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import styles from "../../styles/Home.module.scss";
import DeleteButton from "../Controls/DeleteButton";
import * as actions from "../../redux/actions";
import { v4 as uuidv4 } from "uuid";

import {
  createTaskThunk,
  deleteTaskThunk,
  updateTaskThunk,
} from "../../model/task";

const Task = (props) => {
  const dispatch = useDispatch();
  const [cssClass, setCssClass] = useState(styles.inputActive);
  const [taskBody, setTaskBody] = useState(props.task?.body ? props.task.body : "");
  const [taskBodySource, setTaskBodySource] = useState(props.task?.body ? props.task.body : "");
  const [deleted, setDeleted] = useState(false);
  const [edited, setEdited] = useState(false);

  useEffect(() => {
    setTaskBodySource(taskBody);
  }, []);
  
  const { TextArea } = Input;

  const handleTaskBodyInputChange = (e) => {
    if (e.target.value === taskBodySource) {
      setCssClass(styles.inputActive);
      setEdited(false)
    } else {
      setCssClass(styles.inputEdited);
      setEdited(true);
    }
    setTaskBody(e.target.value);
  };

  /**
   * save scenarios:
   *  new task (new card):
   *    -> "stage" the task with id of staged_uuid
   *  updating staged task
   *    -> we keep the existing staged id but update the body
   *  new task (existing card)
   *    -> we create a new task with the existing cardId
   *  updating stored task
   *    -> we update the existing task with the existing cardId and taskId
   */
  const onSave = () => {
    setCssClass(styles.inputSaved);
    const taskStaged = props.task?.id?.substring(0, 6) === "staged";
    setTaskBodySource(props.task?.id ? taskBody : "");
    if (props.task?.id !== undefined && !taskStaged) {
      dispatch(updateTaskThunk(props.boardId, props.cardId, { ...props.task, body: taskBody}));
    } else if (props.cardId !== null) {
      dispatch(createTaskThunk(props.boardId, props.cardId, { ...props.task, body: taskBody}));
      if (!taskStaged) setTaskBody("");
    } else {
      const stagedId = taskStaged ? props.task.id : `staged_${uuidv4()}`;
      dispatch({ type: actions.STAGE_TASK, payload: { id: stagedId, body: taskBody} });
      if (!taskStaged) setTaskBody("");
    }
    setEdited(false);
  };

  const onDelete = () => {
    const taskStaged = props.task.id.substring(0, 6) === "staged";
    if (taskStaged) {
      dispatch({ type: actions.UNSTAGE_TASK, payload: { taskId: props.task.id} });
    } else {
      dispatch(deleteTaskThunk(props.task.id));
    }
    setDeleted(true);
  };

  return deleted ? null : (
    <Row
      key={props.task?.id}
      className={[
        styles.taskRow,
        styles.debugInput,
        props?.even ? styles.greyBg : styles.blackBg
      ].join(" ")}
    >
      <Col span={20}>
        <TextArea
          onFocus={(e) =>
            e.currentTarget.setSelectionRange(
              e.currentTarget.value.length,
              e.currentTarget.value.length
            )
          }
          autoSize={{minRows: 2}}
          id="taskBodyInput"
          placeholder="add a task"
          value={taskBody}
          rows={4}
          cols={100}
          onChange={handleTaskBodyInputChange}
          className={[
            styles.inputMaximised,
            props?.even ? styles.greyBg : styles.blackBg,
            cssClass
          ].join(' ')}
          autoComplete="off"
        />
      </Col>
      <Col span={4}>
        <Row justify="center" align="middle">
        {props.task?.id ?
          <Col
            span={24}
            align="middle"
            style={{paddingLeft:"15px"}}
          >
            <DeleteButton
              key={`deleteBtn-${props.task?.id}`}
              id={props.task?.id}
              delete={() => onDelete()}
              style={styles.deleteButton}
            />
          </Col> : null}
          <Col
            span={24}
            align="middle"
            style={{padding:"15px"}}
          >
            <button
              key={`saveBtn-${props.task?.id}`}
              type="primary"
              onClick={() => onSave()}
              disabled={!edited}
              className={[
                styles.saveTaskButton,
                styles.transparentBg,
                edited ? styles.saveTaskButtonActive : styles.saveTaskButtonInactive
              ].join(' ')}
              size={"large"}
              shape={"circle"}
              style={{ width:"10px" }}
            >
              {props.task?.id ? <SaveOutlined /> : <PlusOutlined />}
            </button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Task;
