import moment from 'moment';
import app from "firebase/app";
import * as actions from "../redux/actions";


export const search = async (user, searchTerms, fromDate, toDate) => {
  const db = app.firestore();
  const earliestDate = moment('1970-01-01').unix() * 1000;
  const latestDate = moment('9999-01-01').unix() * 1000;
  searchTerms.forEach(term => term.toLowerCase());
  const searchResult = await db
    .collection("mentions")
    .orderBy("createdAt", "desc")
    .where("uid", "==", `${user.uid}`)
    .where("createdAt", ">=", fromDate ?? earliestDate)
    .where("createdAt", "<=", toDate ?? latestDate)
    .where("mentions", "array-contains-any", searchTerms)
    .get()
    .then((querySnapshot) => {
      const mentions = [];
      querySnapshot.forEach((doc) => {
        mentions.push({ id: doc.id, ...doc.data() });
      });
      return mentions;
    })
    .catch((error) => {
      console.error("Error getting boards: ", error);
    });
  return searchResult;
};

function makeArrayDistinct(arr) {
  return arr.sort().filter(function(item, pos, ary) {
      return !pos || item !== ary[pos - 1];
  });
}

function filterSearchResult(searchTerms, searchResults) {
  const numSearchTerms = searchTerms.length;
  const filteredSearchResults = searchResults.filter(result => {
    let termFoundCount = 0;
    result.mentions.forEach(mention => {
      if (searchTerms.indexOf(mention) >= 0) termFoundCount++;
    });
    return termFoundCount === numSearchTerms ? true : false;
  });
  return filteredSearchResults;
}

export function searchThunk(searchTerms, fromDate, toDate) {
  return async function searchInner(dispatch, getState) {
    const state = getState();
    const distinctSearchTerms = makeArrayDistinct(searchTerms);
    const searchResult = await search(state.user.user, distinctSearchTerms, fromDate, toDate);
    const filteredSearchResults = distinctSearchTerms.length > 1 ? filterSearchResult(distinctSearchTerms, searchResult) : searchResult;
    dispatch({
      type: actions.LOAD_SEARCH_RESULT,
      payload: {
        searchResult: filteredSearchResults,
      },
    });
  };
}
