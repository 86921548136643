import app from "firebase/app";

import { deleteMentionThunk } from "./mention";

export const deleteItemsByBoardId = async (
  type,
  boardId,
  deleteFn,
  dispatch
) => {
  const db = app.firestore();
  try {
    const itemIds = await db
      .collection(type)
      .where("boardId", "==", boardId)
      .get()
      .then((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push(doc.id);
        });
        return items;
      });
    const batch = db.batch();
    for (const id of itemIds) {
      deleteFn(id);
      dispatch(deleteMentionThunk(id));
    }
    batch.commit();
    return true;
  } catch (err) {
    console.error(`Error deleting items: ${err}`);
  }
};

export function deleteItemsByBoardIdThunk(type, boardId, deleteFn) {
  return async function deleteItemsByBoardIdOuter(dispatch) {
    if (boardId === "") return;
    await deleteItemsByBoardId(type, boardId, deleteFn, dispatch);
  };
}
