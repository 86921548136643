import React from "react";
import { useParams } from "react-router-dom";
import Board from "../../components/Boards/Board";
import SecurePage from "../../config/auth/SecurePage";

const BoardPage = (props) => {
  let params = useParams();
  return (
    <SecurePage>
      <Board boardId={params.boardId} />
    </SecurePage>
  );
};

export default BoardPage;
