import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Button, Col, Popover, Row } from "antd";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import styles from "../../styles/Home.module.scss";

import SignoutButton from "../SignoutButton/SignoutButton";

const NavPopover = (props) => {
  const [visible, setVisible] = useState(false);
  const user = useSelector((state) => state.user);

  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };

  const popoverPublic = (
    <>
      <Row style={{marginBottom:"10px"}}>
        <Col span={24}>
          <Button className={styles.tealBtn}>
            <Link type="primary" to={`/auth/signup`}>
              Sign Up
            </Link>
          </Button>
        </Col>
      </Row>
      <Row style={{marginBottom:"10px"}}>
        <Col span={24}>
          <Button className={styles.tealBtn}>
            <Link type="primary" to={`/auth/signin`}>
              Sign In
            </Link>
          </Button>
        </Col>
      </Row>
      <Row style={{marginBottom:"10px"}}>
        <Col span={24}>
          <Button className={styles.tealBtn}>
            <Link type="primary" to={`/`}>
              Home
            </Link>
          </Button>
        </Col>
      </Row>
    </>
  );

  const popoverPrivate = (
    <>
      <Row style={{marginBottom:"10px"}}>
        <Col span={24}>
          <Button type="primary" href={"/boards"} className={styles.tealBtn}>
            Boards
          </Button>
        </Col>
      </Row>
      <Row style={{marginBottom:"10px"}}>
        <Col span={24}>
          <Button type="primary" href={"/search"} className={styles.tealBtn}>
            Search
          </Button>
        </Col>
      </Row>
      <Row style={{marginBottom:"10px"}}>
        <Col span={24}>
          <SignoutButton />
        </Col>
      </Row>
    </>
  );

  return (
    <Popover
      placement="bottomLeft"
      content={user ? popoverPrivate : popoverPublic}
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <Button
        className={styles.greenBtn}
        icon={
          props.isOpen ? (
            <CloseOutlined style={{ fontSize: "1.4rem" }} />
          ) : (
            <MenuOutlined style={{ fontSize: "1.4rem" }} />
          )
        }
      />
    </Popover>
  );
};

export default NavPopover;
