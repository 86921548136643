import React from "react";

import { Col, Row } from "antd";
import Task from './Task';

const TaskInputBase = (props) => (
  <Row style={{ marginBottom:"1rem" }} align="middle">
    <Col span={24} align="middle">
      <Task boardId={props.boardId} cardId={props.cardId} taskId={null} />
    </Col>
  </Row>
);

export default TaskInputBase;
