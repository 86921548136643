import app from "firebase/app";
import { extractMentions } from "../utils/mentions";

const buildMentionObjs = async (db, user, type, items) => {
  const mentionCreateObjs = [];
  const mentionUpdateObjs = [];

  for (const item of items) {
    // hack - normalise the field names
    const itemText = item?.title ? item.title : item.body;
    const mentions = extractMentions(itemText);
    if (mentions) {
      const mentionRecords = await db
        .collection("mentions")
        .where("itemId", "==", item.id)
        .get()
        .then((querySnapshot) => {
          const mentionItems = [];
          querySnapshot.forEach((mentionRecord) => {
            const mentionData = mentionRecord.data();
            mentionItems.push(
              Object.assign({}, { id: mentionRecord.id, ...mentionData })
            );
          });
          return mentionItems;
        })
        .catch((error) => {
          console.error("Error getting documents: ", error);
        });

      const mentionObj = {
        uid: user.uid,
        createdAt: Date.now(),
        mentions: mentions ?? [],
        itemId: item.id,
        itemText,
        type,
      };

      if (mentionRecords?.length > 0) {
        mentionUpdateObjs.push(
          Object.assign(
            {},
            {
              id: mentionRecords[0].id,
              ...mentionObj,
            }
          )
        );
      } else {
        mentionCreateObjs.push(mentionObj);
      }
    }
  }

  return [mentionCreateObjs ?? [], mentionUpdateObjs ?? []];
};

export const batchUpsertMentions = async (user, type, items) => {
  const db = app.firestore();
  const mentionsRef = db.collection("mentions");

  try {
    if (!items?.length > 0) return items;

    const [mentionCreateObjs, mentionUpdateObjs] = await buildMentionObjs(
      db,
      user,
      type,
      items
    );

    const batch = db.batch();

    if (mentionUpdateObjs?.length > 0) {
      for (const mentionObj of mentionUpdateObjs) {
        const lowerCaseMentions = mentionObj.mentions.map(mention => {
          const cleanMention = mention.replace('@', '').toLowerCase();
          return cleanMention;
        });
        await batch.update(mentionsRef.doc(mentionObj.id), { ...mentionObj, mentions: lowerCaseMentions });
      }
    } else {
      for (const mentionObj of mentionCreateObjs) {
        const lowerCaseMentions = mentionObj.mentions.map(mention => {
          const cleanMention = mention.replace('@', '').toLowerCase();
          return cleanMention;
        });
        await batch.set(mentionsRef.doc(), { ...mentionObj, mentions: lowerCaseMentions } );
      }
    }

    await batch.commit();

    return items;
  } catch (err) {
    console.error("Error adding document: ", err);
    return false;
  }
};

export function batchUpsertMentionsThunk(type, items) {
  return async function batchUpsertMentionsInner(dispatch, getState) {
    const state = getState();
    await batchUpsertMentions(state.user.user, type, items);
  };
}

export const deleteMention = async (itemId) => {
  const db = app.firestore();
  try {
    await db
      .collection("mentions")
      .where("itemId", "==", itemId)
      .get()
      .then((querySnapshot) => {
        const mentionItems = [];
        querySnapshot.forEach((mentionRecord) => {
          mentionRecord.ref.delete();
        });
        return mentionItems;
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
      });
    return true;
  } catch (err) {
    console.error(`Error deleting mention: ${err}`);
  }
};

export function deleteMentionThunk(itemId) {
  return async function deleteMentionOuter() {
    if (itemId === "") return;
    await deleteMention(itemId);
  };
}
