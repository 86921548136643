import React from "react";

import Search from "../components/Search/Search";
import SecurePage from "../config/auth/SecurePage";

const SearchPage = () => {
  return (
    <SecurePage>
      <Search />
    </SecurePage>
  );
};

export default SearchPage;
