import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Input, Row } from "antd";
import { withFirebase } from "../../config/context/firebaseContext";
import Center from "../../components/Layout/Center";
import * as actions from "../../redux/actions";
import styles from "../../styles/Home.module.scss";

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
};

const SignupForm = props => {
  const [state, setState] = useState(INITIAL_STATE);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = (event) => {
    const { email, password } = state;

    props.firebase
      .doCreateUserWithEmailAndPassword(email, password)
      .then((authUser) => {
        setState({ ...INITIAL_STATE });
        props.firebase.setUser(authUser);
        dispatch({
          type: actions.SET_USER,
          payload: { user: authUser, isLoggedIn: true },
        })
        navigate("/", { replace: false });
      })
      .catch((err) => {
        setState({ error: err });
      });

    event.preventDefault();
  };

  const onChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const { email, password, error } = state;

  const isInvalid = email === "" || password === "";

  return (
    <Center>
      <Form>
        <Row style={{marginTop:"25vh"}}>
          <Row justify="center" className={styles.signinForm}>
            <Col>
              <Input
                placeholder="email"
                autoComplete="username"
                id="email"
                name="email"
                type="email"
                onChange={onChange}
              />
            </Col>
          </Row>
          <Row justify="center" className={styles.signinForm}>
            <Col>
              <Input
                placeholder="password"
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                onChange={onChange}
              />
            </Col>
          </Row>
          <Row justify="center" className={styles.signinForm}>
            <Col>
              <Button
                type="primary"
                onClick={onSubmit}
                disabled={isInvalid}
              >
                signup
              </Button>
            </Col>
          </Row>
       </Row>
      </Form>
      {error && (
        <Row justify="center" className={styles.signinForm}>
          <Col>
            <p>{error.message}</p>
          </Col>
        </Row>
      )}
    </Center>
  );
}

export default withFirebase(SignupForm);
