import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import CardDetail from "../../components/Cards/CardDetail";
import Center from "../../components/Layout/Center";
import SecurePage from "../../config/auth/SecurePage";
import NavBar from "../../components/Navigation/NavBar";
import { Col, Row, Spin } from "antd";
import styles from "../../styles/Home.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { getCardDetailThunk } from '../../model/card';
import { getTaskThunk } from "../../model/task";

const CardDetailPage = () => {
  let params = useParams();
  const card = useSelector(state => state.card);
  const dispatch = useDispatch();

  useEffect(() => {
    if (params.cardId) dispatch(getCardDetailThunk(params.cardId));
  }, []);

  return (
    <SecurePage>
      <Center>
        <NavBar />
        <Row>
          <Col>
            <h3 className={`${styles.inputLabel}`}>Cards</h3>
          </Col>
        </Row>
        <Row>
          <Col>{card ? <CardDetail card={card} /> : <Spin />}</Col>
        </Row>
      </Center>
    </SecurePage>
  );
};
export default CardDetailPage;
