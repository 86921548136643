import app from "firebase/app";
import * as actions from "../redux/actions";

import { deleteItemsByBoardIdThunk } from "./generic";
import { deleteCard } from "./card";
import { deleteTask } from "./task";

export const createBoard = async (user, name) => {
  const db = app.firestore();
  const boardRef = db.collection("boards").doc();
  try {
    await boardRef.set({
      createdAt: Date.now(),
      name,
      uid: user.user.uid,
      favourite: false,
      totalCards: 0
    });
    return { id: boardRef.id };
  } catch (err) {
    console.error(`Error creating board: ${err}`);
  }
};

export function createBoardThunk(name) {
  return async function createBoardOuter(dispatch, getState) {
    const state = getState();
    const board = await createBoard(state.user, name);
    dispatch({ type: actions.SET_BOARD, payload: { board } });
  };
}

export const updateBoard = async (updatedBoard, totalCardIncrement = 0) => {
  const db = app.firestore();
  const boardRef = db.collection("boards").doc(updatedBoard.id);
  let board = {};
  try {
    const boardSnapshot = await boardRef.get();
    const currentBoard = { ...boardSnapshot.data() };
    const totalCards = currentBoard ? currentBoard.totalCards : 0;
    board = await boardRef.update({
      favourite: updatedBoard.favourite ? updatedBoard.favourite : false,
      totalCards: totalCards + totalCardIncrement
    });
  } catch (err) {
    console.error(`Error updating board: ${err}`);
  }
  return board;
};

export function updateBoardThunk(board, invalidateBoards = false) {
  return async function updateBoardOuter(dispatch, getState) {
    if (!board) return;
    board = await updateBoard(board);
    if (invalidateBoards) {
      dispatch(fetchBoardsThunk());
    }
  };
}

export const deleteBoard = async (id) => {
  const db = app.firestore();
  const boardsRef = db.collection("boards");
  try {
    await boardsRef.doc(id).delete();
    return true;
  } catch (err) {
    console.error(`Error deleting board: ${err}`);
  }
};

export function deleteBoardThunk(id) {
  return async function deleteBoardOuter(dispatch, getState) {
    const state = getState();
    dispatch(deleteItemsByBoardIdThunk("cards", id, deleteCard));
    dispatch(deleteItemsByBoardIdThunk("tasks", id, deleteTask));
    await deleteBoard(id);
    const boards = await getBoards(state.user.user);
    dispatch({ type: actions.LOAD_BOARDS, payload: { boards } });
  };
}

export const getBoards = async (user) => {
  const db = app.firestore();
  const boards = await db
    .collection("boards")
    .orderBy("favourite", "desc")
    .orderBy("name", "asc")
    .where("uid", "==", `${user.uid}`)
    .get()
    .then((querySnapshot) => {
      const boards = [];
      querySnapshot.forEach((doc) => {
        boards.push({ id: doc.id, ...doc.data() });
      });
      return boards;
    })
    .catch((error) => {
      console.error("Error getting boards: ", error);
    });
  return boards;
};

export function fetchBoardsThunk() {
  return async function fetchBoards(dispatch, getState) {
    const state = getState();
    const boards = await getBoards(state.user.user);
    if (boards === undefined) {
      dispatch({ type: actions.SEND_USER_HOME, payload: {} });
    } else {
      dispatch({ type: actions.LOAD_BOARDS, payload: { boards } });
    }
  };
}

export const getBoard = async (boardId) => {
  const db = app.firestore();
  const boardRef = db.collection("boards").doc(boardId);
  let board = {};
  try {
    const boardSnapshot = await boardRef.get();
    board = { ...boardSnapshot.data() };
  } catch (err) {
    console.error("Error getting board: ", err);
  }
  return board;
};

export function getBoardThunk(boardId) {
  return async function getBoardOuter(dispatch, getState) {
    const board = await getBoard(boardId);
    if (board !== undefined) {
      dispatch({ type: actions.LOAD_BOARD, payload: { board } });
    }
  };
}
