import React from "react";

import { Col, Row } from "antd";
import styles from "../../styles/Home.module.scss";

import NavPopover from "./NavPopover";

const NavBar = () => {
  const navBar = (
    <div className={styles.navBar}>
      <Row>
        <Col span={24}>
          <NavPopover />
        </Col>
      </Row>
    </div>
  );

  return navBar;
};

export default NavBar;
