import React from "react";
import { connect } from "react-redux";
import { Button } from "antd";
import * as actions from "../../redux/actions";
import { withFirebase } from "../../config/firebaseIndex";
import styles from "../../styles/Home.module.scss";

const SignoutButton = ({ firebase, onUserSignout }) => {

  const handleSignout = () => {
    firebase.doSignOut();
    onUserSignout();
  };
  return (
    <Button className={styles.redBtn} onClick={handleSignout}>
      Logout
    </Button>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUserSignout: () =>
      dispatch({
        type: actions.SET_USER,
        payload: { user: null, isLoggedIn: false },
      }),
  };
};

export default withFirebase(connect(null, mapDispatchToProps)(SignoutButton));
