export const GET_USER = "GET_USER";
export const LOAD_BOARD = "LOAD_BOARD";
export const LOAD_BOARDS = "LOAD_BOARDS";
export const LOAD_CARD = "LOAD_CARD";
export const LOAD_CARDS = "LOAD_CARDS";
export const LOAD_SEARCH_RESULT = "LOAD_SEARCH_RESULT";
export const RESET_SEARCH_RESULT = "RESET_SEARCH_RESULT";
export const LOAD_TASK_LIST = "LOAD_TASK_LIST";
export const SET_BOARD = "SET_BOARD";
export const SET_USER = "SET_USER";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const UPSERT_CARD = "UPSERT_CARD";
export const SEND_USER_HOME = "SEND_USER_HOME";
export const LOAD_TASK = "LOAD_TASK";
export const STAGE_TASK = "STAGE_TASK";
export const UNSTAGE_TASK = "UNSTAGE_TASK";
export const UNSTAGE_TASKS = "UNSTAGE_TASKS";
