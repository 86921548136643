export const firebaseConfigDevel = {
	apiKey: 'AIzaSyAj6pAv18c-v0g4bouUg_G-nwU7OPH_LUM',
	authDomain: 'vocale-efb25.firebaseapp.com',
	projectId: 'vocale-efb25',
	storageBucket: 'vocale-efb25.appspot.com',
	messagingSenderId: '142480608808',
	appId: '1:142480608808:web:f429d3ef61f311f1e96f1d',
	measurementId: 'G-3VJ95V454R'
};

export const firebaseConfigProd = {
  apiKey: "AIzaSyDQ3D9OP5GvBqJZNotJLz5Ag_CfxDoUP8o",
  authDomain: "wutt-eaef7.firebaseapp.com",
  projectId: "wutt-eaef7",
  storageBucket: "wutt-eaef7.appspot.com",
  messagingSenderId: "61119427888",
  appId: "1:61119427888:web:d5b561c29e50db426204f5",
  measurementId: "G-QJBWSVW0L5"
};