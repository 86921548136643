import React, { useState } from "react";

import { Col, Popover, Row } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteFilled,
  DeleteOutlined
} from "@ant-design/icons";
import styles from "../../styles/Home.module.scss";

const DeleteButton = (props) => {
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const toggleDeleteConfirm = () => {
    setDeleteConfirm(!deleteConfirm);
  };

  const confirm = (
    <Row justify="space-around" align="middle">
      <Col
        className={styles.confirmRow}
        onClick={() => props.delete(props.id)}
      >
        <button
          className={styles.confirmButton}
        >
          <CheckOutlined />
        </button>
      </Col>
      <Col
        onClick={toggleDeleteConfirm}
        className={styles.closeRow}
      >
        <button
          className={styles.closeButton}
        >
          <CloseOutlined />
        </button>
      </Col>
    </Row>
  );

  const title = (
    <p style={{color:"white", padding:"3px", marginBottom:"0px"}}>
      Don't need this?
    </p>
  );

  const deleteBtn = (
    <Popover
      content={confirm}
      trigger="click"
      title={title}
      visible={deleteConfirm}
      onVisibleChange={toggleDeleteConfirm}
      overlayInnerStyle={{
        color: "white",
        backgroundColor:"black",
        border:"1px solid #343434",
        borderRadius: "10px",
        padding:"none"
      }}
    >
      <button
        size={"large"}
        shape={"circle"}
        className={props.style}
        onClick={toggleDeleteConfirm}
      >
        {props.filled ? <DeleteFilled /> : <DeleteOutlined />}
      </button>
    </Popover>
  );

  return <>{deleteBtn}</>;
};

export default DeleteButton;
