import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import firebase from "firebase/app";
import "firebase/firestore";
import Firebase, { FirebaseContext } from "./config/firebaseIndex";
import { firebaseConfigProd, firebaseConfigDevel } from "./.firebase-config";
import store, { persistedStore } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import "./styles/globals.scss";

// if ( !'%NODE_ENV%' || '%NODE_ENV%' === 'development') {
//   // dev code
// } else {
//   if (!firebase.apps.length) {
//     firebase.initializeApp(firebaseConfig);
//   } else {
//     firebase.app();
//   }
// }

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfigProd);
} else {
  firebase.app();
}

const firestore = firebase.firestore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistedStore}>
        <FirebaseContext.Provider value={new Firebase(firestore)}>
          <App />
        </FirebaseContext.Provider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
