import React, { useEffect, useState } from "react";
import moment from 'moment';

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Card, Col, DatePicker, List, Row } from "antd";
import styles from "../../styles/Home.module.scss";
import { searchThunk } from "../../model/search";
import * as actions from "../../redux/actions";
import Center from "../Layout/Center";
import NavBar from "./../Navigation/NavBar";
import TextInput from "../Inputs/TextInput";

const Search = () => {
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const searchResult = useSelector((state) => state.searchResult);
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  const dateFormat = 'YYYY-MM-DD';

  useEffect(() => {
    dispatch({
      type: actions.RESET_SEARCH_RESULT,
      payload: {
        searchResult: null,
      },
    });
  }, [dispatch]);

  const onDateChange = (_, [fromYYYYMMDD, toYYYYMMDD]) => {
    setFromDate(moment(fromYYYYMMDD, dateFormat).unix() * 1000);
    setToDate(moment(toYYYYMMDD, dateFormat).unix() * 1000);
  }
  
  const onEnter = (searchTerm) => {
    if (!searchTerm) return;
    searchTerm.replace(/[a-zA-Z0-9]+/g, "");
    const searchTerms = searchTerm.trim().split(" ");
    dispatch(searchThunk(searchTerms, fromDate, toDate));
  };

  const formatResult = (result) => {
    if (!result) return null;
    return (
      <Row
        justify="center"
        align="middle"
        className={[styles.searchRow].join(' ')}
      >
        <Col span={24}>
          <Card
            bordered={false}
            className={[styles.searchCard].join(' ')}
          >
            <Link
              key={result.id}
              to={`/${result.type}/${result.itemId}`}
            >
              <h3 style={{ color: "white" }}>{result.itemText}</h3>
            </Link>
          </Card>
        </Col>
      </Row>
    );
  };

  return (
    <Center>
      <NavBar />
      <Row>
        <Col span={24}>
          <h1 className={styles.inputBig}>Search</h1>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div style={{ width: "100%", margin: "auto" }}>
            <TextInput onEnter={onEnter} />
          </div>
        </Col>
      </Row>
      <Row justify="space-around" align="middle">
        <Col justify="space-around" align="middle" span={24}>
          <RangePicker
            size={'large'}
            className={styles.dateSelector}
            format={dateFormat}
            onChange={onDateChange}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {searchResult?.length > 0 ? (
            <List
              header={null}
              footer={null}
              dataSource={searchResult}
              renderItem={(result) => formatResult(result)}
            />
          ) : null}
        </Col>
      </Row>
    </Center>
  );
};

export default Search;
