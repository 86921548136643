import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Col, Row, Spin } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import styles from "../../styles/Home.module.scss";
import {
  getCardDetailThunk,
  deleteCardThunk,
  upsertCardThunk
} from "../../model/card";
import { daysSinceCreation, timestampToJS } from "../../utils/time";

import DeleteButton from "../Controls/DeleteButton";
import TextInput from "../Inputs/TextInput";
import TaskInputBase from "../Inputs/TaskInputBase";
import Task from '../Inputs/Task';

const CardDetail = (props) => {
  const [deleted, setDeleted] = useState(false);
  const card = useSelector((state) => {
    const matchedCard = state.cards.find(c => c.id === props.card?.id)
    return Object.assign({}, matchedCard);
  });
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deleteCardThunk(card));
    setDeleted(true);
  };

  const onUpdateCard = (title, active) => {
    dispatch(upsertCardThunk(card.boardId, {...card, title, active}));
    dispatch(getCardDetailThunk(card.id));
  };

  useEffect(() => {
    console.log(card);
  }, [card]);

  const title = (
    <Row align="middle">
      <Col span={20} align="middle">
        <TextInput
          txt={card.title}
          onEnter={(txt) => onUpdateCard(txt, card.active)}
        />
      </Col>
      <Col span={4} align="middle">
        <DeleteButton
          key={`deleteBtn-${card.id}`}
          id={card.id}
          delete={handleDelete}
          style={styles.deleteButtonBlack}
          filled={true}
        />
      </Col>
    </Row>
  );
 
  const cardDateCompleted = !card.active && card.modifiedAt ? (
    `🎉 Completed 🎉 ${timestampToJS(card.modifiedAt)} (${daysSinceCreation(card.createdAt)} Days)`
  ) : null;

  const cardAgeColor = () => {
    if (card.createdAt) {
      const days = daysSinceCreation(card.createdAt);
      if (days < 7) return styles.activeCardCold;
      if (days < 14) return styles.activeCardWarm;
      return styles.activeCardHot;
    }
  };
  const activeClasses = [
    card.active ? styles.activeCard : styles.inactiveCard
  ];
  const timeClasses = [
    card.active ? styles.timestampActive : styles.timestampInactive
  ];
  activeClasses.push(cardAgeColor());
  timeClasses.push(cardAgeColor());

  const focusedCardDisplay = !card ? null : (
    <Card
      title={title}
      bordered={false}
      headStyle={{
        backgroundColor: "#2E8A8A",
        borderRadius: "0px",
        padding: "0px",
        border: "none"
      }}
      bodyStyle={{
        border: "none",
        padding: "1rem 1rem",
        backgroundColor: "black"
      }}
      className={[styles.boardCard, styles.debugInput].join(" ")}
    >
      <Row justify="middle">
        <Col span={22}>
          <p className={timeClasses.join(" ")}>
            {timestampToJS(card.createdAt)}
          </p>
          <p className={timeClasses.join(" ")}>
            {cardDateCompleted}
          </p>
        </Col>
        <Col span={2}>
          <button
            className={activeClasses.join(" ")}
            onClick={() => onUpdateCard(card.title, !card.active)}
          >
            <CheckCircleOutlined />
          </button>
        </Col>
      </Row>
      <br />
      <Row justify="middle">
        <Col span={24}>
          <TaskInputBase
            boardId={card.boardId}
            cardId={card.id}
          />
          {card.taskList?.length > 0 ?
            card.taskList.map((task, idx) => <Task
                key={task.id}
                boardId={task.boardId}
                cardId={task.cardId}
                task={task}
                even={idx % 2 === 0}
              />
            ) : null}
        </Col>
      </Row>
    </Card>
  );

  return deleted ? null : (
    <Row>
      <Col span={24}>{focusedCardDisplay ?? <Spin />}</Col>
    </Row>
  );
};

export default CardDetail;
